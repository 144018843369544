export type Section = "stats" | "donations" | "subscriptions";

export const useProfileNav = defineStore("profile-nav", () => {
  const active = ref<Section>("stats");
  function setActive(name: Section) {
    active.value = name;
  }

  return {
    active,
    setActive,
  };
});
